@use 'sass:math';

//Colors

$primaryBlue: #00aeef;
$primaryBlueHover: #26bef7;
$secondaryBlue: #6598be;
$lightBlueActive: #bdedff;
$lightBlue: #eaf1f9;
$white: #fff;
$dark: #13253b;
$lightBlueActive1: #42a1c1;
$lightBlueActive2: #bdedff;
$green: #689e00;
$yellow: #c9c443;
$red: #ef3300;
$redHover: #df5300;
$brown: #9e8d76;
$orange: #ec931f;
$orangeHover: #f2b05a;
$darkGray: #acacac;
$gray: #d6d6d6;
$lightGray: #e5e5e5;
$platinum: #e5e5e5;
//$lightGray: rgba(0, 0, 0, 0.1);
$secondaryTableRowColor: #dde3e9;
$transparent: #ffffff00;
$reddish: #cc3e36;
$blush: #f29f83;
$light-blush: #f5b3a6;
$peach: #ffc496;
$light-peach: #ffdbc0;
$cloudyBlue: #b5bedf;
$light-cloudyBlue: #c9d0e8;
$oliveGreen: #b3ce7f;
$coolBlue: #4787b4;
$lightCarminePink: #ed6464;

//Sizes
$xxs: 5px;
$xs: 12px;
$s: 30px;
$m: 55px;
$l: 95px;
$paginationFontSize: 15px;
$sideBarWidth: 180px;
$languageSelectorDropdownWidth: 150px;
$tableFieldVerticalPadding: 9px;
$inputVerticalPadding: 7px;
$textXS: 12px;
$textS: 14px;
$textM: 16px;
$textL: 20px;
$textXL: 22px;
$textXXL: 24px;
$textLineS: 1.5;
$authFieldWidth: 25rem;
$modal-section-horizontal-padding: 15px;
$authenticationHeaderHeight: 96px;
$sideBarIcon: 20px;
$sideBarIconSmall: 18px;
$sideBarWidth: 215px;
$headerBarHeight: 30px;
$tableButton: 30px;
$tableButtonIcon: 15px;
$accountAvatar: 46px;
$logoWidth: 170px;
$horizontalLogoHeight: 30px;
$avatarCommentPreview: 50px;
$statisticTitle: 25px;
$statisticDescription: 15px;
$statisticBlockHeight: 100px;
$statisticIcon: 30px;
$shortDefaultButtonsWidth: 130px;
$defaultButtonsWidth: 230px;
$extraWideDefaultButtonWidth: 280px;
$dropdownButtonWidth: 310px;
$productIconWidth: 1.5rem;
$productIconMarginLeft: 0.5rem;
$heightS: 142px;
$heightM: 188px;

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
}

input[type='checkbox'].alert-danger {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  box-shadow: none;
  font-size: 2em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: none;
}

input,
label,
p,
span,
div,
li,
td,
tr,
select,
textarea {
  font-size: $textS !important;
}

textarea {
  resize: none !important;
}

label {
  margin-bottom: 0 !important;
  color: $dark;
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

hr {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.company-info-form > textarea {
  min-height: 83px;
  height: calc(
          2 * calc(1.5em + 0.75rem + 2px) + calc(#{$textLineS} * #{$textS}) + #{$s}
  ) !important;
}

div:focus,
button:focus {
  outline: none !important;
}

.optionListContainer {
  z-index: 3 !important;
}

#multiselectContainerReact {
  & > div:first-of-type {
    width: 100%;
    position: absolute;
    display: flex;
    overflow: hidden;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.5rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    border-radius: 0;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    span {
      margin-bottom: 0;
      background: $primaryBlue;
    }
  }

  & > div:last-of-type {
    margin-top: calc(1.5em + 0.75rem + 3px);
  }

  ul {
    border-radius: 0;

    li {
      display: flex;

      &:hover {
        background: $primaryBlue;
      }
    }
  }
}

::-webkit-input-placeholder {
  /* Edge */
  color: $gray !important;
}

:-ms-input-placeholder {
  /* Internet Explorer */
  color: $gray !important;
}

::placeholder {
  color: $gray !important;
}

.mt-s {
  margin-top: $s !important;
}

.mt-m {
  margin-top: $m;
}

.mb-fix-s-m {
  margin-bottom: $s - $m;
}

.m-xxs {
  margin: $xxs !important;
}

.mr-xxs {
  margin-right: $xxs !important;
}

.mr-s {
  margin-right: $s !important;
}

.mr-m {
  margin-right: $m !important;
}

.mr-l {
  margin-right: $l !important;
}

.mr-xs {
  margin-right: $xs !important;
}

.mb-s {
  margin-bottom: $s !important;
}

.mb-m {
  margin-bottom: $m !important;
}

.ml-xs {
  margin-left: $xs !important;
}

.ml-xxs {
  margin-left: $xxs !important;
}

.ml-s {
  margin-left: $s !important;
}

.ml-m {
  margin-left: $m !important;
}

.mt-m {
  margin-top: $m !important;
}

.mt-xs {
  margin-top: $xs !important;
}

.mb-xxs {
  margin-bottom: $xxs !important;
}

.mb-xs {
  margin-bottom: $xs !important;
}

.mt-xxs {
  margin-top: $xxs !important;
}

.pb-xxs {
  padding-bottom: $xxs !important;
}

.pt-xxs {
  padding-top: $xxs !important;
}

.h-s {
  height: $heightS;
}

.h-m {
  height: $heightM;
}

.green-text {
  color: $green;
}

.red-text {
  color: $red;
}

#dialog {
  z-index: 9000;
}

#dialog-large {
  z-index: 5000;
}

.pointer {
  cursor: pointer !important;
}

.default-button {
  padding: 0;
  width: $defaultButtonsWidth;
  height: 42px;
  color: $white;
  border: none;
  background-color: $primaryBlue;

  &:hover {
    background-color: $primaryBlueHover;
  }

  .arrow-up,
  .arrow-down {
    margin-left: $defaultButtonsWidth - $xs - 14px;
    position: absolute;
    height: 0;
    bottom: 0;
    margin-bottom: 19.5px;
    display: flex;
    color: #fff;
  }

  .arrow-up {
    border-bottom: 7px solid $white;
  }

  .arrow-down {
    border-top: 7px solid $white;
  }

  span {
    align-items: center;
    text-transform: uppercase;
    white-space: nowrap;
  }
}

.short-default-button {
  width: $shortDefaultButtonsWidth !important;
}

.extra-wide-default-button {
  width: $extraWideDefaultButtonWidth !important;
}

.dark-bg {
  background-color: $dark !important;
}

.staff-chart-nested-table tbody tr td:nth-of-type(3) {
  display: none;
}

.staff-chart {
  .chart-badge-day {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chart-badge-day.blue {
    background-color: $primaryBlue;
    color: $white;
  }

  .chart-badge-day.red {
    background-color: $red;
    color: $white;
  }

  .chart-badge-day.yellow {
    background-color: $yellow;
    color: $white;
  }

  .chart-badge-hour {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chart-badge-hour.blue {
    background-color: $primaryBlue;
    color: $white;
  }

  .chart-badge-hour.red {
    background-color: $red;
    color: $white;
  }

  .chart-data-hour {
    padding: $xxs;
    width: 90px;
    box-shadow: 1px 1px 1px -1px $darkGray;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .staff-date {
    height: 23px;

    .left-side,
    .mode {
      height: 23px;
      background-color: $dark !important;
      color: $white;
      border-bottom: 1px solid $darkGray;
    }

    .mode {
      justify-content: flex-end;
      display: flex;
      flex-wrap: nowrap;

      span:last-of-type {
        margin-right: $s;
      }

      span {
        white-space: nowrap;
      }
    }
  }

  .hour {
    background-color: $dark;
    flex-shrink: 0;
    justify-content: center;
    border-left: 1px solid $darkGray;
    border-right: 1px solid $darkGray;
    display: flex;
    align-items: center;
    width: 90px;
  }

  .day {
    width: 136.8px !important;
  }

  .chart-data-day {
    padding: $xxs;
    width: 136.8px;
    box-shadow: 1px 1px 1px -1px $darkGray;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
  }
}

.dropdown-button,
.second-dropdown-button {
  width: $dropdownButtonWidth;

  span:first-of-type {
    // overflow is 'clip' for Firefox, otherwise - 'hidden'
    text-overflow: ellipsis;
    margin: auto;
    display: block;
  }

  span.arrow-down {
    margin-left: $dropdownButtonWidth - (2 * $xs);
  }

  span.arrow-up {
    margin-left: $dropdownButtonWidth - (2 * $xs);
  }
}

.short-dropdown-button,
.second-short-dropdown-button {
  width: $defaultButtonsWidth;

  span:first-of-type {
    // overflow is 'clip' for Firefox, otherwise - 'hidden'
    text-overflow: ellipsis;
    margin: auto;
    display: block;
  }

  span.arrow-down {
    margin-left: $defaultButtonsWidth - (2 * $xs);
  }

  span.arrow-up {
    margin-left: $defaultButtonsWidth - (2 * $xs);
  }
}

input[type='checkbox'] {
  min-height: 20px !important;
  min-width: 20px !important;
  cursor: pointer !important;
}

input[disabled] {
  cursor: not-allowed !important;
}

.notification {
  font-size: $textM !important;
  margin-bottom: 25px;
  opacity: 1 !important;
  box-shadow: 0px 2px 5px 1px rgba(13, 25, 33, 0.8) !important;
}

.notification-success {
  background-color: $green !important;
}

.secondary-button {
  color: $primaryBlue;
  background-color: transparent;
  border: solid 1px $primaryBlue;

  &:hover {
    color: $primaryBlueHover;
    background-color: transparent;
    border: solid 1px $primaryBlueHover;
  }
}

.react-autosuggest__suggestions-list {
  list-style-type: none;
  padding: 0;
  margin-top: $xxs;
  position: absolute;
  width: $authFieldWidth;

  .suggestion {
    font-size: 1rem;
    background-color: $white;
    justify-content: center;
    padding: 0.375rem 1.5rem 0.375rem 0.75rem;
    cursor: pointer;

    &:hover {
      background-color: $primaryBlue;
      color: $white;
    }
  }
}

.danger-button {
  background-color: $red !important;

  &:hover {
    background-color: $redHover !important;
  }
}

.nested-table-button .danger-button {
  background-color: $red !important;

  &:hover {
    background-color: $redHover !important;
  }
}

.nested-table .level-0 .note-icon path,
.lab-technician-chart .note-icon path {
  color: $secondaryBlue !important;
}

.nested-table .level-0 .note-icon.white path {
  color: $white !important;
}

.lab-technician-chart .nested-table .level-0 {
  background-color: $lightGray !important;
  color: $dark;
}

.lab-technician-chart {
  .table-fix-header {
    overflow-x: scroll;
  }
}

.fixed-header-chart {
  &.gantchart-height {
    .calendar-table,
    .table-fix-header {
      max-height: 63vh;
    }
  }

  &.staffchart-height {
    .calendar-table,
    .table-fix-header {
      max-height: 58vh;
    }
  }

  &.planner-height {
    .users-table,
    .calendar-table,
    .statistic-table {
      max-height: 50vh;
    }
  }

  .table-fix-header {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .table-fix-header thead th,
  .chart-header {
    position: sticky;
    top: 0;
    z-index: 1;
    position: -webkit-sticky;
  }

  .users-table {
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .statistic-table {
    overflow-y: scroll;
    overflow-x: scroll;
  }

  .shifts-table .calendar-table {
    overflow-y: hidden;
    overflow-x: scroll;
    border: none;
  }

  .chart-header {
    display: block;
  }

  th {
    background: $dark;
  }

  .days {
    height: 46px;
    padding-top: 23px !important;
  }

  &.gantchart {
    .range {
      z-index: 2;
      border-left: 1px solid $darkGray;
    }
  }
}

.gantchart {
  .range {
    background-color: $dark;
  }

  @media (max-width: 1400px) {
    td,
    th div,
    span,
    svg,
    p,
    div {
      font-size: 12px !important;
    }
    .day,
    .chart-data {
      width: 35px !important;
    }
  }
}

.gantchart,
.staffchart {
  flex-wrap: nowrap;
  width: 100%;

  table tbody tr td a {
    color: $white !important;
  }

  .documents-table,
  .transaction-table {
    a {
      color: $primaryBlue !important;
    }
  }


  .level-0 .chart-badge {
    background-color: $secondaryBlue;
  }

  .level-1 .chart-badge {
    background-color: $gray;
  }

  .level-2 .chart-badge {
    background-color: $lightGray;
  }

  .chart-badge {
    height: 26px;
    background-color: $primaryBlue;
    align-items: center;
    display: flex;
    width: 100%;

    .check {
      width: 20px;
      height: 20px;
      margin-left: 3px;
      border-radius: 10px;
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        color: $green;
      }
    }

    &.end {
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
    }

    &.start {
      border-bottom-left-radius: 15px;
      border-top-left-radius: 15px;
    }
  }

  .chart-row {
    height: 45px;
    display: flex;
    width: fit-content;
    background-color: $white;
  }

  .chart-data {
    width: 50px;
    box-shadow: 1px 0 1px -1px $darkGray;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
  }

  .staff-date {
    border-left: 1px solid $darkGray;
    background-color: $dark;
  }

  .range,
  .staff-date {
    position: absolute;
    top: 0;
    width: 100%;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $darkGray;
    z-index: 1;

    .date {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: $white;
      z-index: 999999;
    }

    svg {
      color: $white;
    }

    min-width: unset !important;
  }

  .active-mode {
    font-weight: bold;
    color: $primaryBlue;
  }

  .active-mode,
  .not-active-mode {
    cursor: pointer;
  }
}

.nested-table-button {
  height: 21px !important;
  width: 21px !important;

  svg {
    font-size: 12px;
  }
}

.warning-button {
  background-color: $orange !important;

  &:hover {
    background-color: $orangeHover !important;
  }

  svg path {
    color: $white !important;
  }
}

.level-1 .warning-button svg path {
  color: $white !important;
}

.level-1 .danger-button svg path {
  color: $white !important;
}

.nested-table .level-0 .light-button {
  &.table-button-container {
    background-color: $white !important;

    path {
      color: $primaryBlue !important;

      &:hover {
        color: $primaryBlueHover !important;
      }
    }
  }

  &.warning-button {
    background-color: $white !important;

    path {
      color: $orange !important;

      &:hover {
        color: $orangeHover !important;
      }
    }
  }

  &.danger-button {
    background-color: $white !important;

    path {
      color: $red !important;

      &:hover {
        color: $redHover !important;
      }
    }
  }
}

.authentication-container {
  hr {
    height: 1px !important;
    border: none !important;
    background-color: $white;
  }

  h2 {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 500;
    text-align: center;
    font-size: $textXXL;
  }

  img.avatar {
    background-color: $white !important;
  }

  .input-container {
    width: $authFieldWidth;

    span {
      width: $authFieldWidth;
    }
  }

  .authentication-header {
    z-index: 1;
    height: $authenticationHeaderHeight;
    display: flex;
    align-items: center;
    background-color: $primaryBlue;
    top: 0;
    width: 100%;
    position: fixed;

    .menu-flags {
      margin-right: $s;
    }

    & > img {
      width: 500px;
    }
  }

  .authentication-logo {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: $authenticationHeaderHeight;

    img {
      width: $logoWidth;
    }
  }

  .authentication-body {
    padding-top: $authenticationHeaderHeight;
    display: grid;
    height: 100%;
    justify-content: center;

    button {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: $s;

      span {
        display: flex;
        flex: 1;
        justify-content: center;
      }
    }

    h2 {
      margin-bottom: $s;
    }

    .authentication-form {
      background-color: $lightBlue;
      padding: $s;

      .sub-header {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 0;
        letter-spacing: 0.1em;
        font-weight: 200;
        font-size: 22px !important;
      }

      .avatar {
        width: 134px;
        height: 134px;
        background-color: $primaryBlue;
        border-radius: 67px;
        cursor: pointer;

        &:hover {
          background-color: $primaryBlueHover;
        }

        .avatar-icon {
          font-size: 50px;
          color: $white;
        }
      }

      .note {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 10px;
        max-width: 870px;
      }
    }
  }
}

.avatar-in-user-profile-form {
  .avatar {
    width: 134px;
    height: 134px;
    background-color: $primaryBlue;
    border-radius: 67px;
    cursor: pointer;

    &:hover {
      background-color: $primaryBlueHover;
    }

    .avatar-icon {
      font-size: 50px;
      color: $white;
    }
  }

  button {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: $s;

    span {
      display: flex;
      flex: 1;
      justify-content: center;
    }
  }
}

.statistics {
  display: flex;
  margin: 1.5px;
  flex-wrap: wrap;
  justify-content: center;

  .statistic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 32%;

    &:not(:first-of-type) {
      margin-left: 2%;
    }

    &:nth-child(4) {
      margin-left: 0;
    }

    &:nth-child(1) .icon {
      background-color: $orange;
    }

    &:nth-child(2) .icon {
      background-color: $lightBlueActive1;
    }

    &:nth-child(3) .icon {
      background-color: $secondaryBlue;
    }

    &:nth-child(4) .icon {
      background-color: $brown;
    }

    &:nth-child(5) .icon {
      background-color: $green;
    }

    box-shadow: 0px 0px 0px 1.5px $gray;
    height: $statisticBlockHeight;
    border-radius: math.div($statisticBlockHeight, 2);

    .icon {
      position: relative;
      height: $statisticBlockHeight + 3px;
      width: $statisticBlockHeight + 3px;
      border-radius: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      left: -2px;
      top: -2px;
      flex-shrink: 0;
      align-self: flex-start;

      svg {
        font-size: $statisticIcon;
        color: $white;
      }
    }

    .text {
      margin-right: math.div($statisticBlockHeight, 2);
      flex-direction: column;
    }

    .title {
      //word-wrap: break-spaces !important;
      word-wrap: break-word !important;
      font-size: $statisticTitle !important;
    }

    .description {
      font-size: $statisticDescription !important;
    }
  }
}

.dialog-small-container {
  position: fixed;
  width: 390px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $lightBlue;
  padding-left: $m;
  padding-right: $m;
}

.disabled-button {
  background-color: $gray !important;
  cursor: not-allowed !important;

  &:hover {
    background-color: $gray;
    cursor: not-allowed !important;
  }
}

.disabled-secondary-button {
  color: $gray;
  background-color: transparent;
  border: solid 1px $gray;
  cursor: not-allowed !important;

  &:hover {
    color: $gray;
    background-color: transparent;
    border: solid 1px $gray;
    cursor: not-allowed !important;
  }
}

.top-up-table {
  tr td {
    &:last-child {
      text-align: left !important;
      overflow: visible;
      //width: 15%;
      white-space: nowrap;
    }

    &:nth-last-child(2) {
      width: 50px;
    }

    &:nth-child(1) {
      width: 12%;
    }

    &:nth-child(2),
    &:nth-child(3) {
      width: 16%;
    }
  }
}

.top-up {
  word-break: initial;

  .price {
    text-decoration: line-through;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.blue-labels {
  label {
    color: $secondaryBlue;
    font-weight: 900;
    font-size: $textM !important;
  }
}

.blue-label {
  color: $secondaryBlue;
  font-weight: 900;
  font-size: $textM !important;
}

.text-button {
  color: $primaryBlue;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  display: block;
}

.text-button-underline {
  color: $primaryBlue;
  text-align: center;
  cursor: pointer;
  display: block;
  text-decoration: underline;
}

.header-bar-container {
  z-index: 300;
  background-color: $primaryBlue;
  display: inline-block;
  width: 100%;
  min-width: 900px;

  .logo {
    align-self: center;
    margin-left: $xs;
    height: $horizontalLogoHeight;
    cursor: pointer;
    width: $sideBarWidth - $xs - $xs;
  }
}

.header-bar-text {
  margin-left: $s;
}

.sign-out-button {
  font-size: $textM;
  margin-left: auto;
  margin-right: $s;
  color: $white;
  text-transform: uppercase;
  cursor: pointer;
}

.date {
  display: flex;
  flex-direction: column;

  input {
    width: 115px !important;
  }
}

.text-danger {
  padding-top: 0 !important;
  line-height: 1;
}

.input-container {
  margin-top: $s;
}

.full-width-element {
  margin-left: -$s;
  margin-right: -$s;
}

.side-bar-icon {
  margin-left: -$xxs;
  min-width: 35px !important;
  font-size: $sideBarIcon;
  color: $white;
}

.side-bar-menu-icon {
  margin-right: $xxs;
  min-width: 35px !important;
  font-size: $sideBarIcon;
  color: $white;
}

.side-bar-submenu-icon {
  margin-left: -$xxs;
  min-width: 30px !important;
  font-size: $sideBarIconSmall;
  color: $white;
}

.header-bar-icon {
  font-size: $sideBarIcon;
  color: $white;
}

.label-icons {
  color: $secondaryBlue;
  font-weight: 900;
  font-size: $textM !important;
  margin-right: $xs;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 3px;
  margin-right: 2rem;
  padding-right: 2rem;
  text-overflow: ellipsis;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid $dark;
  cursor: pointer;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid $dark;
  cursor: pointer;
}

.arrow-up-sub-header,
.arrow-down-sub-header {
  margin-bottom: 8px;
}

.side-bar-container {
  z-index: 100;
  position: fixed;
  background-color: $dark;
  width: $sideBarWidth;
  height: 100%;
  overflow-x: auto;

  /* Added to allow "margin-top: auto" in the last side menu item to show it at the bottom of the menu */
  display: flex;
  flex-direction: column;

  div {
    align-items: center;

    // &:hover {
    //   cursor: pointer;

    //   span,
    //   svg {
    //    color: $primaryBlue;
    //   }
    // }
  }

  & div:nth-last-child(2) {
    margin-bottom: 30px;
  }

  // a {
  //   cursor: default;
  //   display: flex;

  //   &:hover {
  //     text-decoration: none;
  //   }
  // }

  // .active {
  //   cursor: default !important;

  //   span,
  //   svg {
  //     color: $primaryBlue;
  //   }
  // }

  // .pointer {
  //   cursor: pointer !important;
  // }
}

.side-bar-menu {
  margin-top: $s;
  margin-left: $xs;

  // .submenu {
  //   padding-left: $s;
  //   margin-top: -$xs;
  //   span {
  //     font-size: $textXS !important;
  //   }
  // }

  &:hover {
    color: $primaryBlue;

    svg,
    span {
      color: $primaryBlue;
    }
  }

  .hidden {
    display: none !important;
  }

  &:first-child {
    margin-top: $s + $accountAvatar + $xxs;
  }

  /* Used for the "Send feedback" menu item that appears at the bottom of the menu */
  &:last-child {
    margin-top: auto;
    margin-bottom: $s;
  }

  span {
    font-size: $textS;
    color: $white;
    text-transform: uppercase;
    margin-left: $xxs;
    margin-right: $xs;
  }

  &.active {
    cursor: default !important;

    span,
    svg {
      color: $primaryBlue;
    }
  }
}

.company-name {
  span {
    color: $white;
    font-size: $textM;
    letter-spacing: 0.1em;
  }
}

.welcome-message {
  display: flex;
  align-items: center;
  color: $white;
  cursor: pointer;

  span {
    font-size: $textM;
    letter-spacing: 0.1em;
  }
}

.organization-name {
  margin-left: $xs;
  font-weight: bolder;
}

.header-avatar {
  width: $accountAvatar;
  height: $accountAvatar;
  border-radius: math.div($accountAvatar, 2);
  background-color: $lightBlue;
  align-items: center;
  justify-content: center;
  display: flex;
  font-style: italic;
  cursor: pointer;

  span {
    color: $primaryBlue;
    font-size: $textXL !important;
  }
}

.route-container {
  display: flex;
  margin-left: $sideBarWidth;
}

.menu-flags {
  padding-bottom: 0;
  margin-left: auto;

  button {
    align-items: center;
    vertical-align: middle;
    border: 0;

    span {
      span:first-child {
        width: 20px !important;
        font-size: 18px !important;
      }

      span:last-child {
        display: none;
      }
    }
  }

  button:after {
    border-top-color: $white;
    border-bottom-color: $white !important;
  }

  ul {
    left: -80px;
    width: $languageSelectorDropdownWidth;

    li {
      span {
        span:first-child {
          font-size: 16px !important;
        }
      }
    }
  }
}

.separator {
  height: 28px;
  width: 1px;
  background-color: $white;
  margin-right: $xs;
  margin-left: $xs;
}

.total tbody tr:last-child {
  font-weight: bold;
}

.page-container {
  height: auto;
  padding-left: $s;
  padding-right: $s;
  width: 100%;
  align-self: center;
  min-width: 900px;

  h1 {
    margin-bottom: 0;
    letter-spacing: 0.1em;
    font-size: $textXXL;
    text-transform: uppercase;
    font-weight: 700;
  }

  h2 {
    margin-bottom: 0;
    letter-spacing: 0.1em;
    font-weight: 200;
    font-size: $textXL;
  }

  .header {
    display: flex;
    align-items: flex-end;
    margin-bottom: $xs;

    .buttons {
      white-space: nowrap;
      //overflow : hidden;
    }

    .secondary-button {
      width: 180px;
    }

    .buttons > button {
      margin-left: $xs;
    }
  }

  .sub-header {
    margin-bottom: $xxs;

    h2 {
      margin: 0;
    }
  }

  .back-button {
    cursor: pointer;
    font-size: $textXXL;
    color: $primaryBlue;
    margin-right: $s;
  }

  .data-section {
    .sub-header {
      margin: 0;
    }

    .content {
      background-color: $lightBlue;
      padding: $s;

      h2 {
        margin-top: 0 !important;
      }
    }
  }

  .avatar {
    border-radius: 100px;
    width: 200px;
  }
}

.icon-button {
  height: 30px;
  padding: 0;
  color: $white;
  border: none;
  background-color: $primaryBlue;

  &:hover {
    background-color: $primaryBlueHover;
  }

  svg {
    margin: 0 $xs !important;
  }
}

.sub-header {
  svg {
    margin: 0 0 0 $xs !important;
    font-size: 21px;
  }

  .icon-button {
    svg {
      margin: 0 $xs !important;
    }
  }
}

.table-responsive {
  margin-top: $s;
  overflow-x: scroll !important;
}

.editable-inventory {
  thead tr th {
    &:nth-of-type(4n) {
      width: 100px;
    }

    &:nth-of-type(5n) {
      width: 100px;
    }

    &:nth-of-type(7n) {
      width: 160px;
    }

    &:nth-of-type(8n) {
      width: 120px;
    }
  }
}

.full-width {
  width: 100% !important;
}

.data-row {
  & > span {
    display: block;

    &:first-of-type {
      padding-top: 6.25px;
    }
  }

  & > div:first-of-type > span:first-of-type {
    padding-top: 6.25px;
  }
}

.data-col {
  padding-bottom: $s !important;

  & > span {
    display: block;

    &:first-of-type {
      padding-top: 6.25px;
    }
  }
}

.table,
.table-responsive {
  margin-bottom: 0 !important;
  border: none;

  .checkbox {
    margin-top: 0;
  }

  .full-width-input {
    input {
      width: 100%;
    }
  }

  tbody tr td a,
  tbody tr td .link {
    font-size: $textS !important;
    color: $primaryBlue !important;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .total {
    td:last-of-type {
      text-align: left;
    }

    font-weight: bold;
  }

  .order-item-category-icon {
    margin-left: $productIconMarginLeft;

    path {
      color: $secondaryBlue;
    }
  }

  td {
    word-break: break-word;
    padding-top: $xxs;
    padding-bottom: $xxs;

    path {
      color: $white;
    }
  }

  thead {
    background-color: $dark;
    color: $white;
    font-size: $textM;
    font-weight: normal;

    tr th {
      white-space: pre-wrap;
      word-break: keep-all;
      font-size: $textS;
      vertical-align: middle;

      &:last-child {
        text-align: right;
      }
    }
  }

  tbody {
    font-size: $textM;
    font-weight: normal;

    tr td:last-child .table-buttons-container {
      display: flex;

      & > div {
        margin-left: $xs;

        &:first-of-type {
          margin-left: auto;
        }
      }
    }

    tr:nth-child(odd) {
      background-color: $lightBlue;
    }

    tr:nth-child(even) {
      background-color: $white;
    }

    tr td,
    .description {
      font-size: $textS;
      vertical-align: middle;

      &:last-child {
        input {
          margin-left: auto;
        }

        text-align: right;

        readable-input {
          justify-content: flex-end;
        }
      }
    }


    td {
      div.left-align-input {
        &:last-child {
          input {
            margin-left: unset;
          }
        }
      }
    }

  }
}

.clients .table {
  tbody tr td:last-child .checkbox {
    justify-content: flex-end;
  }
}

.table-languages {
  tbody {
    tr:nth-child(odd) {
      background-color: $secondaryTableRowColor;
    }

    td:last-child {
      input {
        margin-left: 0px !important;
      }
    }

    input {
      width: 95%;
    }
  }
}

.table.payment-terms {
  tr td {
    &:last-child {
      input {
        margin-left: 0;
        text-align: start;
      }

      text-align: left;

      .readable-input {
        justify-content: flex-start;
      }
    }
  }

  tr td:first-of-type {
    width: 25%;
  }
}

.table-button-container {
  height: $tableButton;
  width: $tableButton;
  border-radius: 20px;
  background-color: $primaryBlue;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: $primaryBlueHover;
  }
}

.danger {
  background-color: $red;
}

.section-button-container {
  margin-top: $m;
  margin-bottom: $xxs;
}

.first-section,
.page-container .header,
.page-container .error-page {
  margin-top: $s + $accountAvatar + $xxs;
}

.next-section {
  margin-top: $m;
  margin-bottom: $xxs;
}

.modal-section {
  padding: $modal-section-horizontal-padding 0;
  background-color: $secondaryTableRowColor;
}

.status,
.status-without-icon,
.badge-label {
  border-radius: 20px;
  padding: 5px 7px;
  color: $white;
  white-space: nowrap;
  max-height: 32px;

  span {
    font-size: $textS;
    font-weight: 700;
  }
}

.status,
.badge-label {
  display: inline;

  span {
    margin-left: $xxs;
  }
}

.status-without-icon {
  width: 100%;
  text-align: center;
}

.calendar-status {
  border: 1px solid $white;
  background-color: transparent !important;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.modal-with-vertical-scroll {
  overflow-y: auto;
  max-height: 97vh;
}

.date-picker-aligned-left {
  .react-datepicker-popper {
    left: -127px !important;
    transform: translate(908px, -35px) !important;
  }
}

.teeth-map-modal {
  overflow-y: auto;
  max-height: 97vh;

  .color-warning-icon {
    margin-top: 6.25px;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  label {
    white-space: nowrap;
  }

  div:nth-of-type(2n) > div {
    flex-wrap: nowrap;
  }

  .warranty {
    display: block;

    span {
      padding-top: 6.25px;
    }

    input {
      text-align: right;
      padding: 0.375rem 0.75rem 0.375rem 0.75rem;
      width: 75px;
    }
  }

  .warranty,
  .test-date {
    white-space: nowrap;
  }
}

.spinner-modal {
  background-color: transparent !important;

  div {
    cursor: default;
  }
}

.loader {
  z-index: 999999;

  .custom-overlay {
    z-index: 999999;
  }
}

.ReactModalPortal {
  z-index: 0;
}

.custom-modal {
  display: flex;
  position: fixed;
  flex-direction: column;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $lightBlue;
  padding: $s $s 0 $s;

  .photo {
    margin-bottom: 0;
  }

  .input-container {
    width: $authFieldWidth;
  }

  .order-item-modal-product {
    width: $authFieldWidth - $productIconMarginLeft - $productIconWidth - 1rem;
  }

  .read-only-order-item-product-icon {
    margin-left: $productIconMarginLeft;
    color: $secondaryBlue;
  }

  .order-item-product-icon {
    width: $productIconWidth;
    margin-top: 58px;
    margin-left: $productIconMarginLeft;
    color: $secondaryBlue;
  }

  .checkbox {
    padding-top: 9px;
    margin-top: 0;
  }

  input:not([type='radio']) {
    cursor: text;
  }

  h2 {
    margin: 0;
    text-transform: uppercase;
    text-align: center;
    font-size: $textL;
    font-weight: 700;
    color: $secondaryBlue;
  }

  .close-icon {
    position: absolute;
    font-size: $textL;
    right: 20px;
    top: 20px;
    color: $primaryBlue;
    cursor: pointer;
  }

  .file-icon {
    color: $secondaryBlue;
  }

  .modal-description {
    text-align: center;
    margin-top: $s;
    color: $dark;
    width: 390px;
    align-self: center;
    font-weight: 700;
    font-size: $textM !important;
    margin-bottom: 0;
  }

  .modal-description-as-html {
    width: 680px;
    margin-top: $s;
    margin-bottom: 0;
    text-align: left;
  }

  .buttons {
    text-align: center;
    align-self: center;
    margin-top: $xs;
    padding-bottom: $s;

    button {
      width: 150px;
      height: 30px;

      margin-top: $xs;
      margin-left: $xxs;
      margin-right: $xxs;
    }

    .download-button {
      display: inline-flex;
      width: 150px;
      height: 30px;

      margin-top: $xs;
      margin-left: $xxs;
      margin-right: $xxs;

      color: $white;
      border: none;
      background-color: $primaryBlue;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
    }

    .download-button:hover {
      color: $white !important;
      background-color: $primaryBlueHover !important;
      text-decoration: none !important;
    }
  }
}

.custom-checkbox {
  width: 26px;
  height: 26px;
  background-color: $white;
  border-radius: 3px;
  border: 1px solid $lightGray;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.table-date-input {
  display: flex;
  align-items: center;

  input {
    text-align: right;
    padding: 0.375rem 0.75rem 0.375rem 0.75rem;
    width: 115px;
  }

  .currency {
    margin-left: $xxs;
  }
}

.table-input {
  display: flex;
  align-items: center;

  input {
    text-align: right;
    padding: 0.375rem 0.75rem 0.375rem 0.75rem;
    width: 90px;
  }

  .currency {
    //margin-left: $xxs;
    white-space: nowrap;
  }
}

.table-input.small-input {
  input {
    width: 55px;
  }
}

.table-input-align-left {
  input {
    text-align: left;
  }
}

.avatar-label {
  padding-bottom: $xxs;
}

.avatar-buttons {
  display: flex;
  align-self: center;

  button {
    width: 120px;
    height: 30px;

    &:not(:first-of-type) {
      margin-left: $xs;
    }

    span {
      margin-top: 4px !important;
    }
  }
}

.cropper {
  display: flex;
  justify-content: center;

  div:first-of-type {
    position: relative;
    width: 200px;
    height: 200px;
  }
}

.custom-overlay,
.custom-overlay-front {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0) scale(1, 1);
}

.custom-overlay {
  z-index: 5000;
}

.custom-overlay-front {
  z-index: 6000;
}

.form-control:disabled {
  background-color: $gray !important;
}

.teeth-map {
  background-color: $white;
}

.pagination-container {
  margin-top: $s;

  ul {
    margin-bottom: 0;
  }

  .pagination {
    .disabled {
      display: none;
    }

    .page-item {
      align-content: center;
      justify-items: center;
    }

    .page-link {
      width: auto;
      color: $dark;
      border: none;
      border-radius: 0;
      font-weight: normal;
      font-size: $paginationFontSize;

      &:hover {
        background-color: $lightBlue;
      }
    }

    .active {
      z-index: 200;

      .page-link {
        background-color: $dark;
        font-weight: bold;
        color: $white;
      }
    }
  }
}

.summary-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    flex: 1;
  }

  & > div:first-child {
    white-space: nowrap;
  }
}

.checkbox,
.radio {
  display: flex;
  align-items: center;
  margin-top: $xs;

  input {
    margin-right: $xs;
    width: auto;
    height: auto;
  }
}

.negative {
  background-color: $red;
}

.status.active {
  background-color: $secondaryBlue;
}

.completed {
  background-color: $green;
}

.awaiting {
  background-color: $orange;
}

.neutral {
  background-color: $secondaryBlue;
}

.status.pending {
  background-color: $orange;
}

.readable-input {
  select,
  input {
    background-color: transparent;
  }
}

.slider {
  width: 100%;
}

.dropdown-buttons {
  position: absolute;
  display: flex;
  margin-top: 0 !important;
  flex-direction: column;
  margin-left: $xs;
  background-color: $white;
  outline: 1px solid $primaryBlue;
  outline-offset: -1px;
  z-index: 2;

  button {
    background-color: $white;
    color: $primaryBlue;
    width: $dropdownButtonWidth;

    &:hover {
      background-color: $primaryBlue;
      color: white;
    }

    span {
      width: $dropdownButtonWidth - $xs !important;
    }
  }
}

.short-dropdown-buttons,
.second-short-dropdown-buttons {
  position: absolute;
  display: flex;
  margin-top: 0 !important;
  flex-direction: column;
  margin-left: $xs;
  background-color: $white;
  outline: 1px solid $primaryBlue;
  outline-offset: -1px;
  z-index: 2;

  button {
    background-color: $white;
    color: $primaryBlue;
    width: $defaultButtonsWidth;

    &:hover {
      background-color: $primaryBlue;
      color: white;
    }

    span {
      width: $defaultButtonsWidth - $xs !important;
    }
  }
}

.clear-button {
  font-size: $textXL;
  position: absolute;
  margin-left: 115px + $xxs;
  cursor: pointer;

  path {
    color: $red !important;
  }
}

.teeth-map-section {
  background-color: $white;
  justify-content: center;
  display: flex;
  width: 100%;
  padding: $xs 4px $xs 4px;

  .teeth-button {
    cursor: pointer;
  }
}

.teeth-map-section.read-only {
  .teeth-button {
    cursor: default;
  }
}

.color-scheme {
  width: 200px !important;
}

.color-code {
  width: 90px;
  white-space: nowrap;
}

.color-code.readable-input {
  // display: flex;
  align-items: center;

  span {
    line-height: 1.2;
    padding-top: 0;
  }
}

.tab-buttons {
  display: flex;
  cursor: pointer;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 50px;

    span {
      font-weight: 900;
      font-size: $textM;
      margin-left: $xs;
    }

    svg {
      font-size: $sideBarIcon;
    }
  }

  .active-tab {
    background-color: $lightBlue;
    color: $primaryBlue;

    path {
      color: $primaryBlue;
    }
  }

  .not-active-tab {
    background-color: $primaryBlue;
    color: $white;

    path {
      color: $white;
    }
  }
}

.comments-container {
  color: $dark;
  display: flex;
  flex-direction: column;

  & > div {
    display: inline-block;
    min-width: 30%;
    max-width: 75%;
  }

  .active-user-comment {
    background-color: $white;
  }

  .deactivated-user-comment {
    background-color: $gray;
  }

  .my-comment {
    background-color: $lightBlueActive;
  }

  .avatar-comment-preview {
    height: $avatarCommentPreview;
    width: $avatarCommentPreview;
    border-radius: 100px;
  }

  & > span {
    display: block;
    text-align: center;
    font-size: $textM;
    padding-top: $xs;
  }

  & > div {
    padding: $s;
    margin-top: $s;
    border-radius: 20px;
  }

  .comment-date {
    font-size: $textM;
  }

  .comments-user-info-left {
    display: flex;
    margin-left: $xs;
    flex-direction: column;

    span:first-of-type {
      font-size: $textM !important;
      font-weight: bold;
    }

    span:last-of-type {
      color: $secondaryBlue;
    }
  }

  .comments-user-info-right {
    display: flex;
    margin-right: $xs;
    flex-direction: column;
    align-items: flex-end;

    span:first-of-type {
      font-size: $textM !important;
      font-weight: bold;
    }

    span:last-of-type {
      color: $secondaryBlue;
    }
  }

  .comment-text {
    padding-top: $s;
    font-size: $textM;
  }
}

.filters {
  min-width: 900px;

  .small-input {
    input {
      text-align: left;
      padding: 0.375rem 0.75rem 0.375rem 0.75rem;
      width: 115px;
    }
  }

  .arrow-down,
  .arrow-up {
    position: absolute;
    top: 50%;
  }

  .task-td {
    padding: 0rem;
    width: 210px;

    input {
      min-width: 180px !important;
    }
  }

  .long-input {
    input {
      width: 200px;
    }
  }

  .interval-input {
    input {
      text-align: left;
      width: 115px;
    }
  }
}

.teeth-mask-section {
  display: flex;
  position: absolute;
  width: 912px;
  height: 359px;
  flex-direction: column;
  justify-content: space-between;

  .teeth-mask {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 57px;
  }

  .implant,
  .no-tooth {
    position: absolute;
    width: 57px;
    display: flex;

    img {
      position: absolute;
      width: 57px;
      filter: invert(53%) sepia(1%) saturate(764%) hue-rotate(179deg) brightness(96%) contrast(101%);
    }
  }

  .no-tooth.top-mask {
    top: 0;
    padding-top: 20px;
    background-color: white;
    height: 109px !important;
  }

  .no-tooth.bottom-mask {
    bottom: 0;
    padding-bottom: 20px;
    background-color: white;
    height: 99px !important;
    align-items: flex-end;

    img {
      transform: rotateX(180deg);
    }
  }

  .implant.top-mask {
    top: 0;
    padding-top: 15px;
    background-color: white;
    height: 73px;

    img {
      transform: rotateX(180deg);
    }
  }

  .implant.bottom-mask {
    bottom: 0;
    padding-bottom: 5px;
    background-color: white;
    height: 63px;
  }

  .active-mask {
    position: absolute;
    width: 57px;
    border: $primaryBlue solid 0.5px;
  }

  .top-mask {
    height: 174px;
  }

  .bottom-mask {
    height: 160px;
  }

  .teeth-mask-first-row {
    display: flex;
    height: 174px;

    .teeth-mask {
      -webkit-flex-direction: column;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  .teeth-mask-second-row {
    .teeth-mask {
      justify-content: flex-end;
      -webkit-flex-direction: column-reverse;
      flex-direction: column-reverse;
    }

    display: flex;
    height: 160px;
  }

  .teeth-dropdown-buttons {
    position: absolute;
    display: block;
    border: $primaryBlue solid 0.5px;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: $defaultButtonsWidth;
      height: 42px;
      color: $primaryBlue;
      border: none;
      background-color: $white;
      cursor: pointer;

      &:hover {
        background-color: $primaryBlue;
        color: $white;
      }

      span {
        text-transform: uppercase;
        text-align: center;
      }
    }
  }

  .teeth-button {
    align-self: center;
    height: 27px;
    width: 27px;
    border: $darkGray solid 2px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $darkGray;
  }

  .active-teeth-button {
    background-color: $primaryBlue;
    color: $white;
    border: none;
  }
}

.tab-content {
  background-color: $lightBlue;
  padding: $s;
}

.photo {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  margin-bottom: 10px;
  border: 1px solid $lightGray;
}

.photo-no-border {
  border: none !important;
}

.photo-rectangle {
  padding-bottom: 70% !important;
}

.nonPhotoFileRegion {
  margin-top: 10px;
  text-align: center;
  font-weight: 700;
}

.photo > div {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo-file-input {
  opacity: 0;
  cursor: pointer;
  height: 0.1px;
  width: 0.1px;
}

.photo-label {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-weight: 400;
  color: $secondaryBlue;
}

.photo-label:focus,
.photo-label:hover {
  cursor: pointer;
}

.photo-label-with-background {
  padding: 8px !important;
  padding-bottom: 4px !important;
  background-color: $white;
  opacity: 0.6;
  border-radius: 6px;
}

.photo-label-with-background:focus,
.photo-label-with-background:hover {
  opacity: 1;
}

.photo-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.photo-modal {
  .input-container {
    width: auto;
  }

  min-width: 715px;

  img {
    max-height: 60vh;
    max-width: 60vw;
    width: auto;
    align-self: center;
    height: auto;
  }
}

.photo-modal-title {
  text-transform: none !important;
  font-weight: 400 !important;
}

.photo-add-button {
  color: $white;
  background-color: $primaryBlue;
  border: none !important;
}

.photo-add-button:focus,
.photo-add-button:hover {
  background-color: $primaryBlueHover;
  cursor: pointer;
}

.react-datepicker__tab-loop {
  z-index: 7000;
}

.face-icon {
  float: right;
  height: 32px;
  margin-top: 3px;
  filter: invert(59%) sepia(27%) saturate(636%) hue-rotate(163deg) brightness(92%) contrast(85%);
}

.face-left-profile-icon {
  transform: rotateY(180deg);
}

.filter-button {
  position: absolute;
  right: $s;
  margin-top: 14px + $s;
}

.payment-status-icon {
  color: $white;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-shrink: 0;

  &.red {
    background-color: $red;
  }

  &.gray {
    background-color: $darkGray;
  }

  &.green {
    background-color: $green;
  }
}

.passive-clinic-icon {
  path {
    color: $orange !important;
  }
}

.deactivated-icon {
  margin-left: 0.25rem;

  path {
    color: $red !important;
  }
}

.note-icon {
  font-size: $textM;

  path {
    color: $dark !important;
  }
}

.default-svg-icon-color {
  filter: invert(55%) sepia(88%) saturate(201%) hue-rotate(163deg) brightness(87%) contrast(94%);
}

.font-awesome-normal-icon-size {
  font-size: 16px !important;
}

.normal-icon-size {
  width: 20px;
  height: 20px;
}

.elongated-icon-size {
  width: 20px;
  height: 15px;
}

.color-warning-icon {
  margin-left: $xxs;
  filter: invert(78%) sepia(24%) saturate(7404%) hue-rotate(348deg) brightness(97%) contrast(90%);
}

.table.paid-amount {
  white-space: nowrap;

  tr td {
    &:last-child {
      span {
        margin-left: auto;
      }

      input {
        margin-left: 0px;
        text-align: right;
      }

      text-align: right;

      .readable-input {
        justify-content: flex-end;
      }
    }
  }

  tr td:first-of-type {
    width: 25%;
  }
}

.orders {
  th:last-of-type,
  td:last-of-type {
    width: 5%;
    white-space: nowrap;
  }
}

.history {
  thead tr th:last-child,
  tbody tr td:last-child {
    text-align: left;
  }

  tbody tr td {
    vertical-align: top;
  }

  th:last-of-type,
  td:last-of-type {
    width: 70%;
  }

  th:first-of-type,
  td:first-of-type {
    width: 15%;
  }
}

.additional-photo-description {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}

.video-react-big-play-button {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.modal-dialog-error-message {
  margin-right: $s;
  margin-bottom: 40px;
  display: block;
}

.modal-dialog-buttons-below-error-message {
  margin-top: $s + $xxs !important;
}

.global-product-modal-content {
  width: 900px;
}

.send-invitation-modal {
  width: 550px;
}


//TODO - not in use;
//.general-information {
//	margin: -9% 50% !important;
//}
//
//.general-information-admin {
//	margin: -13.5% 50% !important;
//}

.empty-div {
  height: 4rem;
}

.planner {
  div {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  span {
    align-items: center;
    text-transform: uppercase;
    white-space: nowrap;
    color: $primaryBlue;
    margin-left: $xs;
  }
}

.clickable-icon {
  path {
    color: $primaryBlue !important;
    cursor: pointer;
  }
}

.calendar {
  span {
    align-items: center;
    text-transform: uppercase;
    white-space: nowrap;
    color: $primaryBlue;
    margin-left: $xs;
    cursor: pointer;
  }
}

.hours {
  width: 139px;
}

.minutes {
  width: 240px;

  option:not(:first-of-type) {
    color: $dark;
  }
}

.color-gray {
  color: $gray !important;
}

.color-dark {
  color: $dark !important;
}

.table-button-container svg {
  color: $white;
}

.task-section {
  .read-only-field {
    #multiselectContainerReact div {
      background-color: $gray !important;
    }
  }
}

.read-only-field {
  pointer-events: none;

  input,
  select,
  textarea,
  button {
    background-color: $gray !important;
  }

  .default-button,
  .danger-button,
  .table-button-container,
  #multiselectContainerReact {
    background-color: $gray !important;
  }
}

.default-button.read-only-field {
  background-color: $gray !important;
}

.danger-button.read-only-field {
  background-color: $gray !important;
}

.phase-name {
  width: 200px;
}

.table-responsive {
  margin-top: 0;
}

.nested-table {
  .collapsed {
    -webkit-box-shadow: inset 0 -1px 0 $darkGray;
    -moz-box-shadow: inset 0 -1px 0 $darkGray;
    box-shadow: inset 0 -1px 0 $darkGray;
    //border-bottom: $darkGray 1px solid;
  }

  //tbody td:first-child, thead th:first-child{
  //  overflow: hidden;
  //  max-width: 125px;
  //  text-overflow: ellipsis;
  //}

  td,
  th div {
    white-space: nowrap;
  }

  tr td .table-buttons-container {
    display: flex;

    & > div {
      margin-left: $xs;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  .level-1.background-dark-gray {
    background-color: $gray !important;
  }

  .level-0 {
    background-color: $secondaryBlue !important;
    color: $white;

    path {
      color: $white !important;
    }
  }

  .level-1 {
    background-color: $lightGray !important;

    .text-right path {
      color: $dark !important;
    }
  }

  .level-2 {
    background-color: $lightGray !important;
  }

  th,
  td,
  thead th {
    border: none !important;
  }

  @media (max-width: 1400px) {
    th {
      padding: 0.75rem 0.15rem !important;
    }
    td {
      padding: 0.75rem 0.15rem !important;
    }
  }
}

.react-datepicker__header--time {
  display: none;
}

.overflow-visible {
  overflow: visible;
}

.item-tasks-modal {
  //min-width: 110vh;
  overflow-y: auto;
  max-height: 97vh;

  h2 {
    color: black;
  }

  .clear-button {
    margin-left: 11.5rem;
  }
}

.gantchart-filters {
  min-width: 1000px;

  .react-datepicker {
    display: flex;
  }

  .clear-button {
    margin-left: 11.5rem;
  }

  label {
    white-space: nowrap;
  }

  .first-row {
    & > div:not(:last-of-type) {
      width: 40%;
    }

    & > div:nth-of-type(2) {
      width: 15%;
    }

    & > div:nth-of-type(4),
    & > div:nth-of-type(3) {
      margin-top: $m !important;
    }

    flex-wrap: wrap;
    display: flex;
    width: 80%;
  }

  .second-row {
    & > div {
      width: 40%;
      height: 56px;
    }

    & > div:nth-of-type(4) {
      width: 10%;
    }

    flex-wrap: wrap;
    display: flex;
    width: 100%;

    & > div.row {
      width: auto;
      margin-bottom: $s;
    }
  }

  @media (min-width: 1500px) {
    .first-row {
      & > div:not(:last-of-type) {
        width: 40%;
      }

      & > div:nth-of-type(2) {
        width: 15%;
      }

      & > div:nth-of-type(4),
      & > div:nth-of-type(3) {
        margin-top: $m !important;
      }

      flex-wrap: wrap;
      display: flex;
      width: 80%;
    }
    .second-row {
      & > div {
        width: 40%;
        height: 56px;
      }

      & > div:nth-of-type(4) {
        width: 10%;
      }

      flex-wrap: wrap;
      display: flex;
      width: 100%;
    }
  }

  @media (min-width: 1400px) {
    .first-row {
      & > div:not(:last-of-type) {
        width: 20%;
      }

      & > div:nth-of-type(2) {
        width: 10%;
      }

      & > div:nth-of-type(4),
      & > div:nth-of-type(3) {
        margin-top: $s !important;
      }

      flex-wrap: nowrap;
      display: flex;
      width: 80%;
    }
    .second-row {
      & > div {
        width: 20%;
        height: 56px;
      }

      & > div:nth-of-type(4) {
        width: 20%;
      }

      flex-wrap: nowrap;
      display: flex;
      width: 100%;
    }
  }
}

.users-table {
  th {
    height: 46px;
    border-bottom: none !important;
  }

  .table th,
  .table td {
    border-top: none;
  }

  width: 15%;

  table thead {
    tr {
      background-color: $dark !important;
    }
  }

  tr {
    background-color: $lightGray !important;
    height: 45px;

    td {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 50px;
      overflow: hidden;
    }
  }
}

.mode {
  color: $white;
  white-space: nowrap;
}

.shifts-table,
.calendar-table {
  border-left: 1px solid $darkGray;
  border-right: 1px solid $darkGray;
  width: 60%;
  position: relative;
  background-color: $lightGray;

  .range {
    height: 23px;
    color: $white;
    border-bottom: 1px solid $darkGray;
    margin: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    min-width: 190px;
    background-color: $dark;
    position: absolute;
    top: 0;
    width: 100%;
    border-bottom: 1px solid $darkGray;
    z-index: 2;
  }

  .days {
    color: $white;
    margin: 0 !important;
    display: flex;
    height: 46px;
    padding-top: 23px !important;
    background-color: $dark;

    .day {
      background-color: $dark;
      flex-shrink: 0;
      justify-content: center;
      width: 50px;
      border-left: 1px solid $darkGray;
      border-right: 1px solid $darkGray;
      display: flex;
      align-items: center;
    }
  }

  .data-row {
    display: flex;
  }

  .data-element {
    flex-shrink: 0;
    justify-content: center;
    width: 50px;
    color: $darkGray;
    border-left: 1px solid $darkGray;
    border-right: 1px solid $darkGray;
    border-bottom: 1px solid $darkGray;
    display: flex;
    align-items: center;
    height: 45px;
    z-index: 0;
  }

  .scrollable-x {
    overflow-x: scroll;
  }
}

.calendar-table {
  width: 100%;
  overflow-x: scroll;
}

.light-blue {
  background-color: $lightBlue;
}

.text-dark-gray {
  color: $darkGray;
}

.statistic-table {
  width: 25%;

  .statistic-header {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    height: 46px;
    background-color: $dark;
    color: $white;
    display: flex;
    position: sticky;
    top: 0;

    .abbreviation {
      width: 100%;
      justify-content: center;
      border-left: 1px solid $darkGray;
      border-right: 1px solid $darkGray;
      display: flex;
      align-items: center;
    }
  }

  .data-row {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    height: 45px;
    display: flex;
  }

  .data-element {
    width: 100%;
    color: $dark;
    font-size: 14px;
    border-left: 1px solid $darkGray;
    border-right: 1px solid $darkGray;
    border-bottom: 1px solid $darkGray;
    display: flex;
    align-items: center;
    height: 45px;
    justify-content: center;
    background-color: $lightGray;
  }
}

.staff {
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
}

.staff-badges {
  margin-bottom: $s;

  .badge {
    display: flex;
    align-items: center;

    div {
      width: 31px;
      height: 31px;
      margin-right: $xs;

      &.red {
        background-color: $red;
      }

      &.blue {
        background-color: $primaryBlue;
      }

      &.yellow {
        background-color: $yellow;
      }

      &.white {
        border: 1px solid $gray;
      }
    }
  }
}

.absence-badges,
.staff-badges {
  margin-left: auto;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  & > div {
    margin-top: $s;
  }

  & > div:not(:first-of-type) {
    margin-left: $xs;
  }
}

.mark-days-table {
  border-collapse: collapse;
  border-bottom: 1px solid #ddd;

  thead,
  tbody {
    display: block;
  }

  tr {
    display: flex;
    justify-content: space-between;

    td,
    th {
      border: none !important;
      width: 100%;
    }
  }

  tbody {
    max-height: 190px;
    overflow-y: auto;
  }

  tbody tr .checkbox {
    padding-top: 0;

    input {
      width: auto;
      margin-left: auto !important;
      margin-right: 0;
    }
  }
}

.badge-label span {
  margin-right: $xxs;
}

.read-only-item {
  margin-top: $s;

  input {
    background-color: transparent;
    border: none;
    padding: 0;
    pointer-events: none;
  }
}

// .add-tasks-form {
//   width: 100vh  !important;
// }

.add-tasks-container {
  padding: $modal-section-horizontal-padding 0;
  background-color: $secondaryTableRowColor;

  tbody {
    width: 100vh !important;

    tr:nth-child(odd) {
      background-color: $secondaryTableRowColor;
    }
  }
}

.task-container {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  align-items: flex-end;
}

.task-field {
  width: 20rem;

  .input-container {
    width: 20rem;
  }
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.task-buttons {
  .default-button, .base-button {
    width: 255px !important;
    height: 40px !important;
  }
}

.table-add-tasks {
  tr {
    padding: 0rem 0.5rem;
  }

  td {
    width: 233px;
  }

  td:nth-last-child(1) {
    display: block !important;
    text-align: left !important;
    clear: both;
  }

  tr,
  table tr td:nth-last-child(1) {
    display: block !important;
    text-align: left !important;
  }

  top: 0;

  .w-20 {
    width: 20%;
    display: contents;
  }

  .task-td {
    padding: 0rem;
    width: 210px;

    input {
      min-width: 180px !important;
    }
  }

  .add-btn-td {
    min-width: 150px !important;

    .tasks-add-btn {
      float: right;
    }
  }

  .td-notes {
    width: 56.9rem;
  }
}

.table-tasks {
  //width: 100vh;
  th:nth-child(1),
  td:nth-child(1) {
    width: 100%;
  }

  tr td:nth-child(5) .table-buttons-container {
    display: flex;

    & > div {
      margin-left: $xs;

      &:first-of-type {
        margin-left: auto;
      }
    }
  }

  tbody {
    tr:nth-child(odd) {
      background-color: $secondaryTableRowColor;
    }

    // td:nth-of-type(5) {
    //   width: 100%;
    // }
  }

  th {
    min-width: 230px;
    padding-left: 1.2rem !important;
  }

  // th:last-child {
  //   min-width: 213px;
  // }
  table tr th:nth-last-child(1),
  table tr td:nth-last-child(2) {
    min-width: 120px;
    max-width: 139px;
    padding-left: 0 !important;
  }

  tr,
  table tr td:nth-last-child(1) {
    display: block;
    text-align: left !important;
    padding: 0;
    padding-right: 0 !important;

    .hidden {
      display: none !important;
    }

    td {
      border: none;
      padding-left: 1.2rem !important;
    }
  }

  .td-notes {
    padding: 0 0 0.75rem 0;
    margin: 0 calc(120px + 0.75rem) 0 0;
  }

  .td-notes-hidden {
    display: none !important;
  }

  .table-buttons-container {
    display: flex;
    justify-content: flex-end;
    padding: 0px 6px;
  }

  .task-td {
    width: 200px;

    input {
      min-width: 180px !important;
    }
  }

  .task-info-icon {
    background-color: transparent;

    path {
      color: $secondaryBlue;
    }

    height: 2rem;
  }

  .task-caret-down-icon {
    background-color: transparent;

    path {
      color: black;
    }
  }

  .task-info-icon svg {
    height: 1.3rem;
    width: 1.3rem;
  }
}

.add-template-container,
.conflict-section {
  background-color: $secondaryTableRowColor;
  padding: 1.2rem;
}

.orange-label {
  color: $orange;
}

.holiday-event {
  width: 100%;
  height: 100%;
}

.holiday-calendar {
  .rbc-event {
    background-color: transparent;
  }

  .rbc-today {
    background-color: $white;
  }

  .rbc-now {
    font-weight: normal;
  }

  .rbc-event-content {
    position: relative;
    height: 100%;
  }

  .toolbar-container {
    background-color: $dark;

    label {
      color: $white;
    }
  }

  .rbc-date-cell {
    padding: $xxs $xs;

    a {
      font-size: 12px;
      color: $dark;
    }

    .columnheader {
      font-size: 12px;
    }
  }

  .rbc-header {
    background-color: $dark !important;
    color: $white;

    span {
      font-size: 11px !important;
      font-weight: normal;
      vertical-align: text-bottom;
    }
  }

  .label-date {
    padding-left: $xxs;
    font-weight: normal;
    font-size: 12px;
  }

  .cell-full-height {
    width: 99%;
  }
}

.rbc-calendar {
  .rbc-off-range-bg {
    background-color: $lightGray;
  }

  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid $darkGray;
  }

  .rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-right: 1px solid $darkGray;
  }

  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid $darkGray;
  }

  .rbc-selected-cell {
    background-color: $white;
  }

  .rbc-header,
  .rbc-month-view {
    border-bottom: 1px solid $darkGray;
  }

  .rbc-month-view {
    border: 1px solid $darkGray;
  }

  .rbc-month-row {
    cursor: pointer;
  }

  .rbc-date-cell {
    a {
      cursor: default;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .rbc-off-range-bg.rbc-selected-cell {
    background-color: $lightGray;
  }

  .rbc-row-content {
    height: 100%;

    .rbc-off-range-bg {
      background-color: $lightGray;
    }

    .rbc-row:not(:first-of-type) {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    .rbc-event {
      border-radius: 0;
      height: 100%;
      padding: 0;
    }

    .rbc-row-segment {
      padding: 0;
    }
  }
}

.min-width {
  width: min-content;
}

.abs-cell {
  height: 100%;

  span {
    color: $darkGray;
    position: relative;
    top: 35%;
  }
}

.shift-cell {
  text-align: center;
}

.cell-full-height {
  height: 100%;
}

.no-working-day-cell {
  background-color: $lightBlue;
}

.gantchart-nested-table {
  min-width: 700px;
  //table {
  //  width: 100%;
  //}
  //thead, tbody tr {
  //  display: table;
  //  width: 100%;
  //  table-layout: fixed;
  //}
  //tbody {
  //  display: block;
  //  overflow-y: auto;
  //  table-layout: fixed;
  //  max-height: 200px;
  //}
  //
  ///* Hide scrollbar for Chrome, Safari and Opera */
  //tbody::-webkit-scrollbar {
  //  display: none;
  //}
  //
  ///* Hide scrollbar for IE, Edge and Firefox */
  //tbody {
  //  -ms-overflow-style: none;  /* IE and Edge */
  //  scrollbar-width: none;  /* Firefox */
  //}
}

// @media (min-width: 768px) {
//   .gantchart-nested-table {
//     tbody td:nth-of-type(2),
//     thead th:first-child:nth-of-type(2),
//     tbody td:nth-of-type(3),
//     thead th:first-child:nth-of-type(3) {
//       overflow: hidden;
//       max-width: 125px;
//       text-overflow: ellipsis;
//     }
//   }
// }

.dark-text-span {
  span {
    color: $dark;
    padding: 5px 25px;
  }
}

.dashboard-statistics {
  justify-content: space-between;

  .statistic:not(:first-of-type) {
    margin-left: 0px !important;
  }

  .statistic {
    width: 24%;
    cursor: pointer;

    &:nth-child(1) .icon {
      background-color: $reddish;
    }

    &:nth-child(2) .icon {
      background-color: $blush;
    }

    &:nth-child(3) .icon {
      background-color: $peach;
    }

    &:nth-child(4) .icon {
      background-color: $cloudyBlue;
    }
  }
}

.dashboard-calendar {
  .rbc-calendar {
    height: 470px !important;
  }

  .rbc-header,
  .toolbar-container {
    line-height: 2.5 !important;
  }

  .rbc-calendar .rbc-month-row {
    cursor: auto;
  }

  .rbc-event-content {
    position: relative;
    height: 100%;
  }

  .rbc-event {
    cursor: auto;
  }

  .dashboard-event {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .dashboard-event-overdue {
    border: 1px solid $red;
  }

  .rbc-event {
    background-color: transparent;
  }

  .rbc-event-date-cell {
    display: flex;
    justify-content: space-evenly;
    margin-top: $xxs;
  }

  .rbc-date-cell {
    padding: $xxs $xs;

    a {
      font-size: 12px;
      color: $dark;
    }

    .columnheader {
      font-size: 12px;
    }
  }

  .circle-cell {
    height: 40px;
    width: 40px;
    margin: $xxs;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    //font-size: 12px !important;
  }

  .circle-cell.completed-count {
    background-color: $oliveGreen;
  }

  .circle-cell.in-progress-count {
    background-color: $coolBlue;
  }

  .circle-cell.not-planned-count {
    background-color: $lightCarminePink;
  }

  .circle-cell.to-be-sent-count {
    background-color: $orange;
  }

  .toolbar-container {
    background-color: $dark;

    label {
      color: $white;
    }
  }

  .rbc-month-header {
    background-color: $dark;

    span {
      color: $white;
    }
  }

  .icon-button {
    height: 30px;
    padding: 0;
    color: $white;
    border: none;
    background-color: $transparent;

    &:hover {
      background-color: $transparent;
      cursor: pointer;
    }

    svg {
      margin: 0 $xs !important;
    }
  }
}

.dashboard-calendar-legend {
  display: flex;
  justify-content: flex-end;

  .completed-count {
    background-color: $oliveGreen !important;
    margin-right: $s;
  }

  .in-progress-count {
    background-color: $coolBlue !important;
    margin-right: $s;
  }

  .not-planned-count {
    background-color: $lightCarminePink !important;
    margin-right: $s;
  }

  .to-be-sent-count {
    background-color: $orange !important;
  }

  span {
    margin-left: $m;
    margin-right: $m;
  }
}

.test-dates-section {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .icon-button {
    color: black;
    background-color: $secondaryTableRowColor;
  }
}

.edit-calendar-order-item-modal {
  min-width: 500px;

  .task-name {
    color: black;
  }

  .time-div {
    display: flex;
    justify-content: space-between;

    .time-field {
      input {
        min-width: 180px !important;
      }
    }

    .end-time-field {
      padding-right: 30px !important;
    }
  }

  .clear-button {
    margin-left: 11.5rem;
  }

  .task-buttons {
    display: flex;
    justify-content: center;

    .default-button {
      width: 175px !important;
    }
  }

  .conflict-section {
    max-width: 550px;
  }
}

.hidden-item {
  display: none;
}

.task-blocked {
  background-color: $cloudyBlue !important;

  &:hover {
    background-color: $light-cloudyBlue !important;
  }
}

.task-overdue {
  background-color: $red !important;

  &:hover {
    background-color: $redHover !important;
  }
}

.task-due-today {
  background-color: $blush !important;

  &:hover {
    background-color: $light-blush !important;
  }
}

.task-due-tomorrow {
  background-color: $peach !important;

  &:hover {
    background-color: $light-peach !important;
  }
}

.work-schedule-calendar {
  pointer-events: none;
}

.assignees-select {
  width: 200px;
  margin-bottom: 32px;

  #multiselectContainerReact span {
    text-align: center !important;
  }
}

.side-bar-menu-dropdown {
  margin-top: $s;
  margin-left: $xs;

  button {
    padding: 0 !important;
    border: none;
    background: none;
    text-transform: uppercase;
    color: $white;
    white-space: pre-line;
    word-break: break-word;

    &:hover {
      color: $primaryBlue;

      svg {
        color: $primaryBlue;
      }
    }
  }

  &.active {
    button,
    button > svg {
      cursor: default !important;
      color: $primaryBlue;
    }
  }

  ul > li {
    list-style: none;
    color: $white;
    text-transform: uppercase;
    margin: 16px 0;

    &:hover {
      cursor: pointer;
      color: $primaryBlue;

      svg {
        color: $primaryBlue;
      }
    }
  }

  ul > li.active {
    cursor: default;
    color: $primaryBlue;

    svg {
      color: $primaryBlue;
    }
  }

  .side-bar-menu-dropdown-item {
    font-size: $textXS !important;
  }

  .side-menu-dropdown-icon {
    font-size: $sideBarIcon;
    margin-left: auto;
    margin-right: $xs;
  }
}


.full-page-modal {
  max-height: 90vh;
  width: 97vw;
  padding-right: 0;
  padding-top: 60px;
  padding-left: 20px;


  .fixed-top {
    height: 60px;
  }

  .modal-content {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 30px;
    background-color: $lightBlue;
    border: none;


    .table-input {
      position: relative;
      width: 100%;

      input {
        width: 100%;
        text-align: left;
      }

      span.text-danger {
        width: 100%;
        max-width: 100%;
        top: 100%;
      }
    }


    .section.discount-section {
      .text-danger {
        width: 200%;
        max-width: unset;
      }

      .table-input {
        input {
          text-align: left;
        }
      }
    }

    .section {
      position: relative;
      background-color: $lightGray;
      padding: 10px;
      padding-top: 34px;
      padding-bottom: 34px;
      margin-top: 10px;


      .text-danger {
        max-width: 10%;
      }

      div.cell {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
      }

      .table-inputreadable-input.data-row {
        padding: 0.375rem 0.75rem 0.375rem 0rem;
      }
    }

    .i-section {
      .section {
        .text-danger {
          max-width: 100%;
          width: 100%;
        }
      }
    }

    table {
      margin-top: 10px;

      td {
        .input-container {
          width: unset;
          margin-top: 0px;
        }
      }

      .total {
        background-color: $lightGray;
      }
    }

    .input-container {
      width: unset;
      margin-top: 0px;
    }

    .buttons {
      button.default-button {
        width: 230px;
        height: 42px;
      }
    }
  }

  .sub-header {
    h2 {
      text-transform: none;
    }
  }

  .sub-header {
    + hr {
      display: none;
    }
  }
}

.discount-header {
  background-color: $dark;
  font-size: 14px;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
  color: $white;
  font-weight: bold;
}

.checkbox {
  padding-top: 0px;
}

.modal-error-message {
  color: $reddish;
  width: 25rem;
}

.left-label {
  white-space: nowrap;
  padding-right: $xs;
}

.custom-table-header {
  background-color: $dark;
  padding-left: 10px;
  height: $s;
  color: $white;
  font-weight: 700;
}

.custom-table-row {
  height: 43px;
}

.padding-right-12 {
  padding-right: 12px;
}

.custom-date-input {
  display: flex;
  align-items: center;

  input {
    text-align: left;
    padding: 0.375rem 0.75rem 0.375rem 0.75rem;
    width: 115px;
  }

  .currency {
    margin-left: $xxs;
  }
}

///////
//ORDER INVOICE TABLE
// CREDIT NOTE TABLE////////////////
////////

.table.order-invoice-table thead > tr > th {
  border-bottom: none;
}

table.table.order-invoice-table th, table.table td {
  border-top: 1px solid $lightGray;
}

table.table.order-invoice-table td.order-info {
  border-top: 1px solid $lightGray;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}


.table.order-invoice-table tbody tr:nth-child(odd), .table-responsive tbody tr:nth-child(odd) {
  background-color: $lightGray
}

.table.order-invoice-table tbody tr:nth-child(even), .table-responsive tbody tr:nth-child(even) {
  background-color: $lightBlue
}

.table.order-invoice-table tbody > tr.order-info,
.table.credit-note-table tbody tr.table-blue-row:nth-child(odd),
.table.credit-note-table tbody tr.table-blue-row:nth-child(even) {
  background-color: $secondaryBlue;
  font-weight: bold;
  color: $white;
}

.table.order-invoice-table tbody > tr.prod-info,
.table.credit-note-table tbody tr:nth-child(odd),
.table.credit-note-table tbody tr:nth-child(even) {
  background-color: $darkGray;
}

.table.credit-note-table {
  .data-row > span:first-of-type {
    padding-top: 0px;
  }

  .data-row {
    span: first-of-type {
      padding-top: 0px;
    }
  }
}

.table.credit-note-table tbody tr:nth-child(odd),
.table.credit-note-table tbody tr:nth-child(even), {
  background-color: $lightGray;
}

.table.credit-note-table tbody tr.brown-table-row:nth-child(odd),
.table.credit-note-table tbody tr.brown-table-row:nth-child(even), {
  background-color: $gray;
}

.table.order-invoice-table tbody > tr.order-invoice-total,
.table.credit-note-table tbody > tr.total {
  background-color: $darkGray;
  font-weight: bold;
}

table.order-invoice-table,
table.credit-note-table {
  .arrow-down.arrow-light {
    border-top: 7px solid $white;
  }
}

.padding-x-15 {
  padding: 0 15px;
}

.button-ml-xs > button {
  margin-left: $xs;
}

table.credit-note-table .data-row > div:first-of-type > span:first-of-type {
  padding-top: 0px;
}

.btn-link {
  color: $primaryBlue !important;
}

div.sub-header.blue {
  .arrow-up-sub-header {
    border-bottom: 7px solid $secondaryBlue
  }

  .arrow-down-sub-header {
    border-top: 7px solid $secondaryBlue
  }
}

.table.localisation-table thead tr th:last-child, .table-responsive thead tr th:last-child {
  text-align: left;
}

.table.localisation-table tbody tr:nth-child(odd), .table-responsive tbody tr:nth-child(odd),
.table.localisation-table tbody tr:nth-child(even), .table-responsive tbody tr:nth-child(even) {
  background-color: $lightGray;
}

.sub-header.left-icon {
  svg {
    margin: 0 $xs 0 0 !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.incomes-details-filters,
.outcomes-details-filters {
  .date {
    input {
      width: 100% !important;
    }
  }

  .clear-button {
    right: $xs*-1;
    z-index: 1000;
  }

  .table-input input {
    width: 100%;
    text-align: left;
  }

  .amount {
    .text-danger {
      top: 100%
    }
  }
}

.incomes-details-reminder-fixed-top {
  position: fixed;
  top: 55px;
  right: 0;
  width: calc(100% - 215px);
  padding: 10px;
  background-color: $lightBlue;
}

.outcomes-details-filters {
  .red-label {
    color: $red;
  }

  .red-label + div {
    span {
      color: $red;
    }
  }
}

.pdf-modal-input {
  width: 12rem !important;
}

.custom-modal {
  .balance-table {
    thead {
      tr {
        th {
          white-space: pre-wrap;
        }
      }
    }
  }
}

.btn-link:hover {
  text-decoration: none !important;
}

.btn-link:focus,
.btn-link.focus, {
  text-decoration: none !important;
}

.readable-input-row-padding {
  padding: 6px 0;
}

.balance {
  button.secondary-button {
    background-color: $white;
  }
}


.billing-modal.full-page-modal {
  padding-top: 100px;

  .fixed-top {
    height: 100px;
    justify-content: center;

    h2 {
      padding-top: 30px;
    }
  }
}


.balance-modal {
  .short-dropdown-buttons {
    margin-left: 0px;
  }
}


.custom-modal.full-page-modal.income-outcome-modal {
  .modal-content {
    padding: 0;
  }

  .buttons {
    margin-top: 0;
    align-self: end;
    padding: 0;

  }
}

.statement-open-close {
  .short-dropdown-buttons, .second-short-dropdown-buttons {
    margin-left: 0;
  }
}

.custom-modal.full-page-modal.pre-create-modal {
  .modal-content {
    .section {
      span.text-danger {
        max-width: 100%;
      }
    }
  }
}


.btn.base-button {
  padding: 0;
  width: $defaultButtonsWidth;
  height: 42px;
  border-radius: 0;
  vertical-align: unset;

  span {
    align-items: center;
    text-transform: uppercase;
    white-space: nowrap;
  }
}







